<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
            <CRow>
               <CCol col="6" class="text-left"><h4>User Transactions</h4></CCol>
               <CCol col="6" class="text-right">
                  <CButton v-on:click="showFormPasswd()" color="info">Download</CButton>
               </CCol>
            </CRow>
        </CCardHeader>
        <CCardBody>

         <CRow>
            <CCol col="12" class="text-left">
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol>
         </CRow>

         <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
         </div>

                     <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>
                        </b-card>
                     </b-card-group>


        <b-card-group>
          <b-card  header="" header-tag="header" footer-tag="footer" title="">
               <b-row>
                  <b-col sm="3">
                        <CInput
                           label="From Date"
                           type="date"
                           vertical
                           v-bind:readonly="isReadOnly"
                           v-model="fromDate"
                           @input="startDateOnChange()"
                        />
                  </b-col>
                  <b-col sm="3">
                     <CInput
                           label="To Date"
                           type="date"
                           vertical
                           v-bind:readonly="isReadOnly"
                           v-model="toDate"
                           @input="endDateOnChange()"
                        />
                  </b-col>
                  <b-col sm="3">
                     <div class="form-group">
                           <b-row>
                           <b-col sm="6">
                           <label for="input-small" >Status</label>
                           </b-col>
                           <b-col sm="6">
                           <b-form-checkbox
                           id="cbstatus"
                           v-model="cbstatus"
                           name="cbstatus"
                           @change="onModifyClick"
                           :disabled="isDisabled"
                           >
                           All
                           </b-form-checkbox>
                           </b-col>
                           </b-row>
                           <multiselect :disabled="isDisabled" v-bind="attrMSelect" @select="toggleStsSelected" @remove="toggleStsUnSelect" v-model="defOptSts" :options="optStatus" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"  label="name" track-by="name" :preselect-first="true">
                              <template slot="selection1" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                           </multiselect>
                     </div>
                  </b-col>
                  <b-col sm="3">
                     <div class="form-group">
                           <label for="input-small" >Currency</label>
                           <multiselect :disabled="isDisabled" @select="toggleCurrSelected" @remove="toggleCurrUnSelect" v-model="defOptCurr" :options="optCurrency" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true">
                              <template slot="selection2" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                           </multiselect>
                     </div>
                  </b-col>
               </b-row>
               <b-row>
                <b-col sm="4">
                     <div class="form-group">
                     <CInput
                        v-bind="attrsFrom"
                        type="number"
                        min='0'
                        @change="amtFromChange"
                        v-model="amountFrom"
                        :id="amountFrom"
                        label="Amount From"
                        v-bind:readonly="isReadOnly"
                        required
                      />
                      </div>
                </b-col>
                <b-col sm="4">
                     <div class="form-group">
                        <CInput
                        v-bind="attrsTo"
                        type="number"
                        min='0'
                        @change="amtToChange"
                        v-model="amountTo"
                        :id="amountTo"
                        label="Amount To"
                        v-bind:readonly="isReadOnly"
                        required
                        />
                     </div>
                </b-col>
                <b-col sm="4">
                     <div class="form-group">
                         <b-row>
                           <b-col sm="4">
                              <label for="input-small" >Type</label>
                           </b-col>
                           <b-col v-if="seenCbOtUsr" sm="9">
                              <b-form-checkbox
                                 id="cbReSts"
                                 v-model="cbReSts"
                                 name="cbReSts"
                                 @change="_onModifyClick">
                               To External User
                              </b-form-checkbox>
                           </b-col>
                        </b-row>
                        <b-form-select :disabled="isDisabled" @change="typeChange" v-model="defoptType" :options="optType"></b-form-select>
                     </div>
                </b-col>
                <!-- <b-col sm="3">
                        <b-row>
                           <label for="input-small" >Sub Type</label>
                           <b-form-select @change="subTypeChange" v-model="subType" :options="optSubType"></b-form-select>
                        </b-row>
                </b-col> -->

                <!--b-col sm="2">
                    <CInput
                        type="number"
                        min='1'
                        max='24'
                        @change="timeRangeChange"
                        v-model="timeRange"
                        :id="timeRange"
                        label="Time Range"
                        description="1 - 24 Hours"
                        required
                        was-validated
                        />
                </b-col>
                <b-col sm="2">
                     <div class="form-group">
                        <label for="input-small" >Start Time</label> <br/-->
                        <!--vue-timepicker @change="timeChange" v-model="time" format="HH:mm:ss"></vue-timepicker>
                        <vue-timepicker @change="timeChange" v-model="time" format="HH:mm" :minute-interval="10"></vue-timepicker>
                     </div>
                </b-col-->
               </b-row>
               <b-row>
                  <b-col sm="10">
                     <div class="form-group">
                        <label for="input-small" >Search</label>
                        <b-form-input v-model="filter" @change="filterChange" placeholder="Type to Search" :id="search"></b-form-input>
                     </div>
                  </b-col>
                  <b-col sm="2"><br/>
                        <b-button variant="primary" v-on:click="filterApplied()" >Apply Filters</b-button>
                  </b-col>
               </b-row>
          </b-card>
        </b-card-group><br/>

        <b-row v-if="pbar">
            <b-col sm="12">
               <b-progress :max="max" height="2rem" show-progress animated>
                  <b-progress-bar :value="value" variant="success" :label="`${((value / max) * 100).toFixed(2)}%`"></b-progress-bar>
               </b-progress>
            </b-col>
         </b-row><br/>

        <div class="table-responsive table">
           <b-table striped hover :items="items" :fields="fields" sort-by="transactionDate" :sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter">

              <template v-slot:cell(topUpCode)="{ item }">
                {{ item.topUpCode | filterOnly }}
              </template>

               <template v-slot:cell(txnFee)="{ item }">
                 {{ item.txnFee | setValDefault }}
              </template>

               <template v-slot:cell(promoAmount)="{ item }">
                 {{ item.promoAmount | setValDefault }}
              </template>

              <template v-slot:cell(transactionDate)="{ item }">
                {{ item.transactionDate | dateSubstr }}
              </template>

              <template v-slot:cell(movementType)="{ item }">
                    {{ item.movementType | changeLabel(item.dbsTransactionType,item.paymentMethod) }}
              </template>


           </b-table>
        </div><br/>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>
        </div>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import AuthService from '@/api/AuthService.js';
import ReportService from '@/api/ReportService.js';
import DateRangePicker from 'vue2-daterange-picker';
import Multiselect from 'vue-multiselect';
import VueTimepicker from 'vue2-timepicker';
import moment from 'moment';
import JsonExcel from "vue-json-excel";

 export default {
    name: "transaction",
    components: {
       Multiselect,
       VueTimepicker,
       'downloadExcel' : JsonExcel
    },
    data() {
      var date = new Date();
      return {
          isReadOnly:false,
          isDisabled:false,
          json_meta: [
              [
                {
                  key: "charset",
                  value: "utf-8",
                },
              ],
          ],
          pbar:false,
          value: 0,
          max:0,
          idrPaynow:["WALLET_IDR","WALLET_IDR_PROXY"],
          phpPaynow:["WALLET_PHP"],
          dbsMca:["WALLET_DBS_MCA","MANUAL_DBS_MCA"],
          proxyTypeList:["NRIC","MOBILE","UEN"],
          spinner:false,
          json_fields:'',
          json_data:'',
          time:'',
          timeRange:'',
          defOptSts:'',
          defOptCurr:'',
          cbstatus:false,
          cbReSts:false,
          attrsFrom: {
            description : ""
          },
          attrsTo: {
            description : ""
          },
          attrMSelect:{
            placeholder : ""
          },
          fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
          toDate: new Date().toISOString().substr(0, 10),
          defoptType:null,
          optType:[
              { value: 'P2P', text: 'P2P'},
              { value: null, text: 'ALL'}
          ],
          optStatus: [
              { name: 'COMPLETED'},
              { name: 'CANCELED'},
              { name: 'REFUNDED'}
          ],
          baseOptStatus:[],
          optCurrency: [
                { name: 'SGD' },
                { name: 'IDR' },
                { name: 'USD' }
          ],
          baseOptType:[],
          items:[],
          rows:[],
          type:[],
          status: [],
          currency:[],
          amountFrom:null,
          amountTo:null,
          search:null,
          fields: [
            {
               name : 'transactionId',
               key: 'transactionId',
               label : 'Transaction ID',
               sortable: true
            },
            {
               name : 'transactionDate',
               key: 'transactionDate',
               label : 'Transaction Date',
               sortable: true
            },
            {
               name : 'sender',
               key: 'sender',
               label : 'Received From',
               sortable: true
            },
            {
               name : 'senderPhone',
               key: 'senderPhone.number',
               label : 'Received Phone No.',
               sortable: true
            },
            {
               name : 'beneficiary',
               key: 'beneficiary',
               label : 'Sent To',
               sortable: true
            },
            {
               name : 'beneficiaryPhone',
               key: 'beneficiaryPhone.number',
               label : 'Sent Phone No.',
               sortable: true
            },
            {
               name : 'currency',
               key: 'currency',
               label : 'Currency',
               sortable: true
            },
            {
               name : 'amount',
               key: 'amount',
               label : 'Amount($)',
               sortable: true
            },
            {
               name : 'txnFee',
               key: 'txnFee',
               label : 'Tr. Fee',
               sortable: true
            },
            {
               name : 'promoAmount',
               key: 'promoAmount',
               label : 'Promo Amount',
               sortable: true
            },
            {
               name : 'movementType',
               key: 'movementType',
               label : 'Type',
               sortable: true
            },
            {
               name : 'status',
               key: 'status',
               label : 'Status',
               sortable: true
            }
          ],
          currentPage: 1,
          perPage: 10,
          filter: null,
          data:[],
          columns:[],
          formPasswd:false,
          filename:"user-transaction.xlsx",
          password:"",
          seen:false,
          msg :'',
          color:'',
          userStatus: null,
          seenCbOtUsr:false,
          initialLoad:true,
         };
   },
   filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           value = value.split(".")
           return value[0].replace("T"," ")
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'
        },
        changeLabel(value,dbsTT,payM){
            if(!value) return ''
            if(dbsTT==='BUYNOW') return 'BUYNOW_SGQR'
            if(payM==='WALLET_IDR') return 'IDR_PAYNOW'
            else return value
        },
        filterOnly(value){
            if(!value) return ''
            if(['EW', 'SS'].includes(value.substr(0,2))) return value;
            else return ''
        },
        setValDefault(value){
            if(!value) return 0
            else return value
        }
   },

    created () {

       this.getConfigList();
       this.setDefaultPasswd();
       this.setColumns();
    },
    methods: {

    showFormPasswd(){
      if(!this.pbar){ this.formPasswd = true;
      } else {
         this.msg   = 'Action Ignored, Please waiting until the previous process done !';
         this.color = 'warning'; this.seen  = true;
         setTimeout( () =>  this.seen=false , 5000);
      }
    },

    filterApplied(){
      this.retrieveTransac();
    },

    actionCancel(){
          this.formPasswd = false;
    },

    setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser;
        this.password = user+today;
    },

    generateWorkbook(){

         this.formPasswd = false; this.spinner=true;
         var cols  = this.columns; var rows  = this.data;
         var heads = []; var temp =[]; var data=[];
         let params = {};   var colkey =[];

          for (var key in cols) {
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          }
          data.push(heads);  var jum = heads.length;

          for (var key2 in rows) { var row = rows[key2];
              var init = Array(jum).fill(null);
              for(var key4 in colkey){
                for (var key3 in row) {
                  var val  = row[key3];
                  if(key3==="transactionDate"){
                    var val = new Date(val).toISOString().substr(0, 19).replace("T"," ");
                  }
                  var ckey = colkey[key4];
                  if(key3===ckey){
                     init[key4] = val;
                  }
                }
              }
              data.push(init);
          }

         params['password'] = this.password;
         params['data'] = data;   //console.log(params);
         this.getReportExcel(params);
    },

    getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {  //console.log(resp);
         try {
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click(); this.spinner = false;
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!';
              this.color = 'warning'; this.seen  = true;
              this.spinner = false;
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {
            this.msg   = 'Sorry, Generated Excel Failed!';
            this.color = 'warning'; this.seen  = true;
            this.spinner = false;
            setTimeout( () =>  this.seen=false , 5000);
         }

        }, error => {
           this.spinner = false;
        });
    },

    onModifyClick(e) {
        if(e){
            this.attrMSelect.placeholder="ALL";
            this.defOptSts='';
            this.optStatus=[];
            this.status =[];
            //this.retrieveTransac();
        } else {
            this.attrMSelect.placeholder="Pick Some";
            this.optStatus = this.baseOptStatus;
            //this.retrieveTransac();
        }
    },

    _onModifyClick(e) {
        if(e){
            this.userStatus = "SYSTEM_GENERATED";
            //this.retrieveTransac();
        } else {
            this.userStatus = null;
            //this.retrieveTransac();
        }

    },

    sortByProperty(property){
         return function(a,b){
            if(a[property] > b[property])
               return 1;
            else if(a[property] < b[property])
               return -1;
            return 0;
         }
    },

    getConfigList: function() {
          AuthService.getConfigList().then(resp => {      //console.log(resp.transactionType);

            var currSrc=[];
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ currSrc.push({ name : item}); }
            this.optCurrency = currSrc;

            var typeSrc=[];
            var arrTypes = resp.transactionType;
            typeSrc.push({ value: null  , text: 'ALL' });
            typeSrc.push({ value: 'WALLET_COIN_PHP'  , text: 'COIN_PHP' });
            typeSrc.push({ value: 'DBS_PAYNOW_DASH'  , text: 'DBS_PAYNOW_DASH' });
            typeSrc.push({ value: 'DBS_PAYNOW_GRAB'  , text: 'DBS_PAYNOW_GRAB' });
            typeSrc.push({ value: 'DBS_MCA'  , text: 'DBS_MCA' });
            for (const item2 of arrTypes) {
                if(item2!=="ADMIN") { typeSrc.push({ value: item2 , text: item2 }); }
            }
            typeSrc.push({ value:"WALLET_GRAB_PHP", text:"GRAB_PHP" });
            typeSrc.push({ value: 'IDR_PAYNOW'  , text: 'IDR_PAYNOW' });
            typeSrc.push({ value: 'PHP_PAYNOW'  , text: 'PHP_PAYNOW' });
            //typeSrc.push({ value: 'DBS_PAYNOW_PROXY'  , text: 'DBS_PAYNOW_PROXY' });
            typeSrc.push({ value: 'REDEEM'  , text: 'REDEEM' });
            typeSrc.push({ value: 'SGQR_BUYNOW'  , text: 'SGQR_BUYNOW' });


            typeSrc.sort(this.sortByProperty("text"));
            this.optType = typeSrc;
            this.baseOptType = typeSrc;

            var mvStsSrc=[];
            var arrMvSts = resp.movementStatus;
            for (const item3 of arrMvSts){ mvStsSrc.push({ name : item3 }); }
            this.optStatus = mvStsSrc;
            this.baseOptStatus = mvStsSrc;

           }, error => {
            console.log('error');
          });
    },

    getRequestParams(stardate,enddate) {
        let params = {};
        var term = this.time;
        var re = new RegExp("^[0-9:]*$");
        var isRemittance = ['IDR_PAYNOW','PHP_PAYNOW','DBS_MCA', 'WALLET_GRAB_PHP', 'WALLET_COIN_PHP'];
        var isDbsPaynow  = ['SGQR_BUYNOW','DBS_PAYNOW_DASH','DBS_PAYNOW_GRAB'];

        if(isRemittance.includes(this.defoptType)){
            var movementType = "REMITTANCE";
        } else if(isDbsPaynow.includes(this.defoptType)){
            var movementType = "DBS_PAYNOW";
        } else if(this.defoptType == 'REDEEM'){
            var movementType = "ADMIN";
        } else {
            var movementType = this.defoptType;
        }

        params["userId"] = null;
        params["currency"] = this.currency;
        params["searchString"] = this.filter;
        params["movementType"] = movementType;
        params["fromDate"] = stardate+"T00:00:00";
        params["toDate"] = enddate+"T23:59:59";
        params["fromRange"] = this.amountFrom;
        params["toRange"] = this.amountTo;
        params["movementStatus"] = this.status;
        params["startTime"] = null; //(re.test(term)) ? term : null;
        params["timeRange"] = null; //this.timeRange;
        params["userStatus"] = this.userStatus;

        return params;
    },

    dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           value = value.split(".")
           return value[0].replace("T"," ")
    },

    setColumns(){
        this.columns = [];
        var fields = this.fields; let row = {}; var cols = [];
        for (var key in fields) {
           if(fields[key].key !== "index"){
             row['label'] = fields[key].label;
             row['field'] = fields[key].key;
             var temp = JSON.stringify(row); cols.push(temp);
           }
        }

        for (var key2 in cols) {
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
    },

    async retrieveTransac(){
      if(!this.pbar){
         this.pbar = true;
         this.isReadOnly = true;
         this.isDisabled = true;
         var startdate = moment(this.fromDate);
         var enddate   = moment(this.toDate);
         var days      = enddate.diff(startdate, "days") +1;
         this.max = 0; this.value = 0;
         var i = days; var lim = 7; var j = days; var k = 1;  var l = 1;
         this.filter = null; this.rows=[]; this.items=[];

         if(days > lim){
            while (j > 0) { if((j > lim )){ this.max = k +1; } j = j-lim;  k++; }
            var start = startdate.format('YYYY-MM-DD');
            while (i > 0) {
               var start_date = (i == days) ? start : moment(end_date).add(1,'days').format('YYYY-MM-DD');
               var end_date = (i > lim ) ? startdate.add(lim, 'days').format('YYYY-MM-DD') : enddate.format('YYYY-MM-DD');
               let params   = this.getRequestParams(start_date, end_date);
               console.log(i+'|'+start_date+':'+end_date);
               await AuthService.getUserTransact(params).then((resp) => {
                     for (var key in resp) {
                        var temp = resp[key];
                        let senderPhone; let beneficiaryPhone;
                        try { senderPhone = temp.senderPhone.number; } catch (error) { senderPhone = "none"; };
                        try { beneficiaryPhone = temp.beneficiaryPhone.number; } catch (error) { beneficiaryPhone = "none"; };

                        let row = {
                              "transactionId"    : temp.transactionId,
                              "transactionDate"  : new Date(temp.transactionDate),
                              "sender"           : temp.sender,
                              "senderPhone.number" : senderPhone,
                              "beneficiary"        : temp.beneficiary,
                              "beneficiaryPhone.number" : beneficiaryPhone,
                              "currency"         : temp.currency,
                              "amount"           : temp.amount,
                              "movementType"     : temp.movementType,
                              "txnFee"           : (!temp.txnFee) ? 0 : temp.txnFee,
                              "promoAmount"      : (!temp.promoAmount) ? 0 : temp.promoAmount,
                              "status"           : temp.status
                        };

                        if(this.defoptType === "DBS_PAYNOW_DASH"){
                              if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'DASH'){
                                 temp.movementType   ="DBS_PAYNOW_DASH";
                                 row['movementType'] ="DBS_PAYNOW_DASH";  /* Defined variable for download excel */
                                 this.rows.push(row); this.items.push(temp);
                              }
                        } else if (this.defoptType === "DBS_PAYNOW_GRAB"){
                              if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'GRAB'){
                                 temp.movementType   ="DBS_PAYNOW_GRAB";
                                 row['movementType'] ="DBS_PAYNOW_GRAB";  /* Defined variable for download excel */
                                 this.rows.push(row); this.items.push(temp);
                              }
                        } else if (this.defoptType === "IDR_PAYNOW"){
                           if(this.idrPaynow.includes(temp.paymentMethod)){
                              temp.movementType   ="IDR_PAYNOW";
                              row['movementType'] ="IDR_PAYNOW";  /* Defined variable for download excel */
                              this.rows.push(row); this.items.push(temp);
                           }
                        } else if (this.defoptType === "PHP_PAYNOW"){
                           if(this.phpPaynow.includes(temp.paymentMethod)){
                              temp.movementType = "PHP_PAYNOW";
                              row['movementType'] = "PHP_PAYNOW";  /* Defined variable for download excel */
                              this.rows.push(row); this.items.push(temp);
                           }
                        } else if(this.defoptType === "DBS_MCA"){
                           if(this.dbsMca.includes(temp.paymentMethod)){
                              var labelType = temp.paymentMethod.replace('_DBS_MCA','');
                              temp.movementType   = labelType;
                              row['movementType'] = labelType;  /* Defined variable for download excel */
                              this.rows.push(row); this.items.push(temp);
                           }
                        } else if (this.defoptType === "REMITTANCE"){
                           if(!this.idrPaynow.includes(temp.paymentMethod)
                              && !this.phpPaynow.includes(temp.paymentMethod)
                              && !this.dbsMca.includes(temp.paymentMethod)
                              && temp.paymentMethod !== "WALLET_GRAB_PHP"
                              && temp.paymentMethod !== "WALLET_COIN_PHP"){
                              row['movementType'] = temp.movementType; /* Defined variable for download excel */
                              row['paymentMethod'] = temp.paymentMethod; /* Additional fields for Remittance */
                              row['customerReference'] = temp.customerReference;
                              this.rows.push(row); this.items.push(temp);
                           }
                        } else if (this.defoptType === "WALLET_GRAB_PHP"){
                          if(!this.idrPaynow.includes(temp.paymentMethod)
                             && !this.phpPaynow.includes(temp.paymentMethod)
                             && !this.dbsMca.includes(temp.paymentMethod)
                             && temp.paymentMethod === "WALLET_GRAB_PHP"){
                              row['movementType'] = "GRAB_PHP"; /* Defined variable for download excel */
                              row['paymentMethod'] = "WALLET"; /* Additional fields for Remittance */
                              row['customerReference'] = temp.customerReference;
                              temp.movementType = "GRAB_PHP";
                              temp.paymentMethod = "WALLET";
                              this.rows.push(row); this.items.push(temp);
                          }
                        } else if (this.defoptType === "WALLET_COIN_PHP"){
                          if(!this.idrPaynow.includes(temp.paymentMethod)
                             && !this.phpPaynow.includes(temp.paymentMethod)
                             && !this.dbsMca.includes(temp.paymentMethod)
                             && temp.paymentMethod === "WALLET_COIN_PHP"){
                              row['movementType'] = "COIN_PHP"; /* Defined variable for download excel */
                              row['paymentMethod'] = "WALLET"; /* Additional fields for Remittance */
                              row['customerReference'] = temp.customerReference;
                              temp.movementType = "COIN_PHP";
                              temp.paymentMethod = "WALLET";
                              this.rows.push(row); this.items.push(temp);
                          }
                        } else if (this.defoptType === "TOP_UP"){
                              var value = temp.topUpCode;
                              row['topUpCode'] = (value.substr(0,2)==='EW') ? value : ''; /* Additional fields for Top Up */
                              this.rows.push(row); this.items.push(temp);
                        } else if (this.defoptType === "SGQR_BUYNOW"){
                           if(temp.dbsTransactionType=='BUYNOW'){
                              row['movementType'] = "SGQR_BUYNOW";
                              temp.movementType   = "SGQR_BUYNOW";
                              this.rows.push(row); this.items.push(temp);
                           }
                        } else if (this.defoptType === "DBS_PAYNOW"){
                           if(temp.dbsTransactionType=='PAYNOW' && this.proxyTypeList.includes(temp.dbsProxyType)){
                              row['movementType'] = "DBS_PAYNOW";
                              temp.movementType   = "DBS_PAYNOW";
                              this.rows.push(row); this.items.push(temp);
                           }
                        } else if (this.defoptType === "REDEEM"){
                           if(temp.systemTransactionType=='REDEEM'){
                              row['movementType'] = "REDEEM";
                              temp.movementType   = "REDEEM";
                              this.rows.push(row); this.items.push(temp);
                           }
                        } else if (this.defoptType === "WECHAT_PAYNOW"){
                           row['trsListingId'] = temp.trsListingId;
                           row['message'] = temp.message;
                           this.rows.push(row); this.items.push(temp);
                        } else {
                           if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'DASH'){
                                 temp.movementType   ="DBS_PAYNOW_DASH";
                                 row['movementType'] ="DBS_PAYNOW_DASH";
                           }
                           else if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'GRAB'){
                                 temp.movementType   ="DBS_PAYNOW_GRAB";
                                 row['movementType'] ="DBS_PAYNOW_GRAB";  /* Defined variable for download excel */
                           }
                           else if(this.idrPaynow.includes(temp.paymentMethod)){
                              temp.movementType   ="IDR_PAYNOW";
                              row['movementType'] ="IDR_PAYNOW";  /* Defined variable for download excel */
                           }
                           else if(this.phpPaynow.includes(temp.paymentMethod)){
                              temp.movementType = "PHP_PAYNOW";
                              row['movementType'] = "PHP_PAYNOW";  /* Defined variable for download excel */
                           }
                           else if(this.dbsMca.includes(temp.paymentMethod)){
                              temp.movementType   = 'DBS_MCA';
                              row['movementType'] = 'DBS_MCA';  /* Defined variable for download excel */
                           }
                           else if(!this.idrPaynow.includes(temp.paymentMethod)
                                    && !this.phpPaynow.includes(temp.paymentMethod)
                                    && !this.dbsMca.includes(temp.paymentMethod)
                                    && temp.dbsTransactionType=='REMITTANCE'){
                              row['movementType'] = temp.movementType; /* Defined variable for download excel */
                              row['paymentMethod'] = temp.paymentMethod; /* Additional fields for Remittance */
                              row['customerReference'] = temp.customerReference;
                           }
                           else if(temp.dbsTransactionType=='BUYNOW'){
                              row['movementType'] = "SGQR_BUYNOW";
                              temp.movementType   = "SGQR_BUYNOW";
                           }
                           else if(temp.dbsTransactionType=='PAYNOW' && this.proxyTypeList.includes(temp.dbsProxyType)){
                              row['movementType'] = "DBS_PAYNOW";
                              temp.movementType   = "DBS_PAYNOW";
                           }
                           else if(temp.systemTransactionType=='REDEEM'){
                              row['movementType'] = "REDEEM";
                              temp.movementType   = "REDEEM";
                           }
                           else if(temp.topUpCode){
                              var value = temp.topUpCode;
                              row['topUpCode'] = (value.substr(0,2)==='EW') ? value : '';
                           }
                           else if(temp.movementType == "REMITTANCE" && temp.paymentMethod == "WALLET_GRAB_PHP"){
                               row['movementType'] = "GRAB_PHP";
                               row['paymentMethod'] = "WALLET";
                               temp.movementType = "GRAB_PHP";
                               temp.paymentMethod = "WALLET";
                           }
                           else if(temp.movementType == "REMITTANCE" && temp.paymentMethod == "WALLET_COIN_PHP"){
                               row['movementType'] = "COIN_PHP";
                               row['paymentMethod'] = "WALLET";
                               temp.movementType = "COIN_PHP";
                               temp.paymentMethod = "WALLET";
                           }

                           this.rows.push(row); this.items.push(temp);

                        }
                     }

                     this.data  = this.rows; this.value = l;
                     console.log(this.rows);
               }).catch((e) => { console.log(e); });
               i = i-lim;  l++;
            }
            console.log('--------------------');
         } else {
            this.max = 1;
            let params  = this.getRequestParams(this.fromDate, this.toDate);
            this.getUserTransact(params,l);
         }
         this.isReadOnly = false;
         this.isDisabled = false;
         setTimeout( () =>  this.pbar=false , 3000);
      } else {
         this.msg   = 'Action Ignored, Please waiting until the previous process done !';
         this.color = 'warning'; this.seen  = true;
         setTimeout( () =>  this.seen=false , 5000);
      }
    },

    async getUserTransact(params,l){

        await AuthService.getUserTransact(params).then((resp) => {   //console.log(resp);

            for (var key in resp) {
                var temp = resp[key];
                let senderPhone; let beneficiaryPhone;
                try { senderPhone = temp.senderPhone.number; } catch (error) { senderPhone = "none"; };
                try { beneficiaryPhone = temp.beneficiaryPhone.number; } catch (error) { beneficiaryPhone = "none"; };

                let row = {
                      "transactionId"    : temp.transactionId,
                      "transactionDate"  : new Date(temp.transactionDate),
                      "sender"           : temp.sender,
                      "senderPhone.number" : senderPhone,
                      "beneficiary"        : temp.beneficiary,
                      "beneficiaryPhone.number" : beneficiaryPhone,
                      "currency"         : temp.currency,
                      "amount"           : temp.amount,
                      "movementType"     : temp.movementType,
                      "txnFee"           : (!temp.txnFee) ? 0 : temp.txnFee,
                      "promoAmount"      : (!temp.promoAmount) ? 0 : temp.promoAmount,
                      "status"           : temp.status
                };

                if(this.defoptType === "DBS_PAYNOW_DASH"){
                     if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'DASH'){
                        temp.movementType   ="DBS_PAYNOW_DASH";
                        row['movementType'] ="DBS_PAYNOW_DASH";  /* Defined variable for download excel */
                        this.rows.push(row); this.items.push(temp);
                     }
                } else if (this.defoptType === "DBS_PAYNOW_GRAB"){
                     if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'GRAB'){
                        temp.movementType   ="DBS_PAYNOW_GRAB";
                        row['movementType'] ="DBS_PAYNOW_GRAB";  /* Defined variable for download excel */
                        this.rows.push(row); this.items.push(temp);
                     }
                } else if (this.defoptType === "IDR_PAYNOW"){
                  if(this.idrPaynow.includes(temp.paymentMethod)){
                       temp.movementType   ="IDR_PAYNOW";
                       row['movementType'] ="IDR_PAYNOW";  /* Defined variable for download excel */
                       this.rows.push(row); this.items.push(temp);
                  }
                } else if (this.defoptType === "PHP_PAYNOW"){
                  if(this.phpPaynow.includes(temp.paymentMethod)){
                       temp.movementType = "PHP_PAYNOW";
                       row['movementType'] = "PHP_PAYNOW";  /* Defined variable for download excel */
                       this.rows.push(row); this.items.push(temp);
                  }
                } else if(this.defoptType === "DBS_MCA"){
                   if(this.dbsMca.includes(temp.paymentMethod)){
                        var labelType = temp.paymentMethod.replace('_DBS_MCA','');
                        temp.movementType = labelType;
                        row['movementType'] = labelType;  /* Defined variable for download excel */
                        this.rows.push(row); this.items.push(temp);
                   }
                } else if (this.defoptType === "REMITTANCE"){
                   if(!this.idrPaynow.includes(temp.paymentMethod)
                      && !this.phpPaynow.includes(temp.paymentMethod)
                      && !this.dbsMca.includes(temp.paymentMethod) ){
                       row['movementType'] = temp.movementType; /* Defined variable for download excel */
                       row['paymentMethod'] = temp.paymentMethod; /* Additional fields for Remittance */
                       row['customerReference'] = temp.customerReference;
                       this.rows.push(row); this.items.push(temp);
                   }
                } else if (this.defoptType === "WALLET_GRAB_PHP"){
                    if(!this.idrPaynow.includes(temp.paymentMethod)
                                && !this.phpPaynow.includes(temp.paymentMethod)
                                && !this.dbsMca.includes(temp.paymentMethod)
                                && temp.paymentMethod === "WALLET_GRAB_PHP"){
                      row['movementType'] = "GRAB_PHP"; /* Defined variable for download excel */
                      row['paymentMethod'] = "WALLET"; /* Additional fields for Remittance */
                      row['customerReference'] = temp.customerReference;
                      temp.movementType = "GRAB_PHP";
                      temp.paymentMethod = "WALLET";
                      this.rows.push(row); this.items.push(temp);
                   }
                } else if (this.defoptType === "TOP_UP"){
                       var value = temp.topUpCode;
                       row['topUpCode'] = (value.substr(0,2)==='EW') ? value : ''; /* Additional fields for Top Up */
                       this.rows.push(row); this.items.push(temp);
                } else if (this.defoptType === "SGQR_BUYNOW"){
                  if(temp.dbsTransactionType=='BUYNOW'){
                       row['movementType'] = "SGQR_BUYNOW";
                       temp.movementType   = "SGQR_BUYNOW";
                       this.rows.push(row); this.items.push(temp);
                  }
                } else if (this.defoptType === "DBS_PAYNOW"){
                  if(temp.dbsTransactionType=='PAYNOW' && this.proxyTypeList.includes(temp.dbsProxyType)){
                       row['movementType'] = "DBS_PAYNOW";
                       temp.movementType   = "DBS_PAYNOW";
                       this.rows.push(row); this.items.push(temp);
                  }
                } else if (this.defoptType === "REDEEM"){
                   if(temp.systemTransactionType=='REDEEM'){
                       row['movementType'] = "REDEEM";
                       temp.movementType   = "REDEEM";
                       this.rows.push(row); this.items.push(temp);
                   }
                } else if (this.defoptType === "WECHAT_PAYNOW"){
                   row['trsListingId'] = temp.trsListingId;
                   row['message'] = temp.message;
                   this.rows.push(row); this.items.push(temp);
                } else {
                  if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'DASH'){
                        temp.movementType   ="DBS_PAYNOW_DASH";
                        row['movementType'] ="DBS_PAYNOW_DASH";
                  }
                  else if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'GRAB'){
                        temp.movementType   ="DBS_PAYNOW_GRAB";
                        row['movementType'] ="DBS_PAYNOW_GRAB";  /* Defined variable for download excel */
                  }
                  else if(this.idrPaynow.includes(temp.paymentMethod)){
                       temp.movementType   ="IDR_PAYNOW";
                       row['movementType'] ="IDR_PAYNOW";  /* Defined variable for download excel */
                  }
                  else if(this.phpPaynow.includes(temp.paymentMethod)){
                       temp.movementType = "PHP_PAYNOW";
                       row['movementType'] = "PHP_PAYNOW";  /* Defined variable for download excel */
                  }
                  else if(this.dbsMca.includes(temp.paymentMethod)){
                        temp.movementType   = 'DBS_MCA';
                        row['movementType'] = 'DBS_MCA';  /* Defined variable for download excel */
                  }
                  else if(!this.idrPaynow.includes(temp.paymentMethod)
                           && !this.phpPaynow.includes(temp.paymentMethod)
                           && !this.dbsMca.includes(temp.paymentMethod)
                           && temp.dbsTransactionType=='REMITTANCE'){
                       row['movementType'] = temp.movementType; /* Defined variable for download excel */
                       row['paymentMethod'] = temp.paymentMethod; /* Additional fields for Remittance */
                       row['customerReference'] = temp.customerReference;
                       temp.movementType = (temp.paymentMethod == "WALLET_GRAB_PHP") ? "GRAB_PHP" : temp.movementType;
                  }
                  else if(temp.dbsTransactionType=='BUYNOW'){
                       row['movementType'] = "SGQR_BUYNOW";
                       temp.movementType   = "SGQR_BUYNOW";
                  }
                  else if(temp.dbsTransactionType=='PAYNOW' && this.proxyTypeList.includes(temp.dbsProxyType)){
                       row['movementType'] = "DBS_PAYNOW";
                       temp.movementType   = "DBS_PAYNOW";
                  }
                  else if(temp.systemTransactionType=='REDEEM'){
                       row['movementType'] = "REDEEM";
                       temp.movementType   = "REDEEM";
                  }
                  else if(temp.topUpCode){
                       var value = temp.topUpCode;
                       row['topUpCode'] = (value.substr(0,2)==='EW') ? value : '';
                  }
                  else if(temp.movementType == "REMITTANCE" && temp.paymentMethod == "WALLET_GRAB_PHP"){
                      row['movementType'] = "GRAB_PHP";
                      temp.movementType = "GRAB_PHP";
                  }

                  this.rows.push(row); this.items.push(temp);
                }
            }

            this.data  = this.rows; this.value = l;

        }).catch((e) => { console.log(e); });

        setTimeout( () =>  this.pbar=false , 2000);
    },

   //  retrieveTransac(){
   //      this.spinner = true;
   //      let params = {}; this.filter = null;
   //      this.getRequestParams(params);
   //      let rows = [];  let items = [];
   //      var idrPaynow = ["WALLET_IDR","WALLET_IDR_PROXY"];
   //      var phpPaynow = ["WALLET_PHP"];
   //      var proxyTypeList = ["NRIC","MOBILE","UEN"];

   //      AuthService.getUserTransact(params).then((resp) => {   //console.log(resp);

   //          for (var key in resp) {
   //              var temp = resp[key];
   //              let senderPhone; let beneficiaryPhone;
   //              try { senderPhone = temp.senderPhone.number; } catch (error) { senderPhone = "none"; };
   //              try { beneficiaryPhone = temp.beneficiaryPhone.number; } catch (error) { beneficiaryPhone = "none"; };

   //              let row = {
   //                    "transactionId"    : temp.transactionId,
   //                    "transactionDate"  : new Date(temp.transactionDate),
   //                    "sender"           : temp.sender,
   //                    "senderPhone.number"      : senderPhone,
   //                    "beneficiary"      : temp.beneficiary,
   //                    "beneficiaryPhone.number" : beneficiaryPhone,
   //                    "currency"         : temp.currency,
   //                    "amount"           : temp.amount,
   //                    "movementType"     : temp.movementType,
   //                    "txnFee"           : (!temp.txnFee) ? 0 : temp.txnFee,
   //                    "promoAmount"      : (!temp.promoAmount) ? 0 : temp.promoAmount,
   //                    "status"           : temp.status
   //              };

   //              if(this.defoptType === "DBS_PAYNOW_DASH"){
   //                   if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'DASH'){
   //                      temp.movementType   ="DBS_PAYNOW_DASH";
   //                      row['movementType'] ="DBS_PAYNOW_DASH";  /* Defined variable for download excel */
   //                      rows.push(row); items.push(temp);
   //                   }
   //              } else if (this.defoptType === "DBS_PAYNOW_GRAB"){
   //                   if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'GRAB'){
   //                      temp.movementType   ="DBS_PAYNOW_GRAB";
   //                      row['movementType'] ="DBS_PAYNOW_GRAB";  /* Defined variable for download excel */
   //                      rows.push(row); items.push(temp);
   //                   }
   //              } else if (this.defoptType === "IDR_PAYNOW"){
   //                if(idrPaynow.includes(temp.paymentMethod)){
   //                     temp.movementType   ="IDR_PAYNOW";
   //                     row['movementType'] ="IDR_PAYNOW";  /* Defined variable for download excel */
   //                     rows.push(row); items.push(temp);
   //                }
   //              } else if (this.defoptType === "PHP_PAYNOW"){
   //                if(phpPaynow.includes(temp.paymentMethod)){
   //                     temp.movementType = "PHP_PAYNOW";
   //                     row['movementType'] = "PHP_PAYNOW";  /* Defined variable for download excel */
   //                     rows.push(row); items.push(temp);
   //                }
   //              } else if (this.defoptType === "REMITTANCE"){
   //                 if(!idrPaynow.includes(temp.paymentMethod) && !phpPaynow.includes(temp.paymentMethod) ){
   //                     row['movementType'] = temp.movementType; /* Defined variable for download excel */
   //                     row['paymentMethod'] = temp.paymentMethod; /* Additional fields for Remittance */
   //                     row['customerReference'] = temp.customerReference;
   //                     rows.push(row); items.push(temp);
   //                 }
   //              } else if (this.defoptType === "TOP_UP"){
   //                     var value = temp.topUpCode;
   //                     row['topUpCode'] = (value.substr(0,2)==='EW') ? value : ''; /* Additional fields for Top Up */
   //                     rows.push(row); items.push(temp);
   //              } else if (this.defoptType === "SGQR_BUYNOW"){
   //                if(temp.dbsTransactionType=='BUYNOW'){
   //                     row['movementType'] = "SGQR_BUYNOW";
   //                     temp.movementType   = "SGQR_BUYNOW";
   //                     rows.push(row); items.push(temp);
   //                }
   //              } else if (this.defoptType === "DBS_PAYNOW"){
   //                if(temp.dbsTransactionType=='PAYNOW' && proxyTypeList.includes(temp.dbsProxyType)){
   //                     row['movementType'] = "DBS_PAYNOW";
   //                     temp.movementType   = "DBS_PAYNOW";
   //                     rows.push(row); items.push(temp);
   //                }
   //              } else if (this.defoptType === "REDEEM"){
   //                 if(temp.systemTransactionType=='REDEEM'){
   //                     row['movementType'] = "REDEEM";
   //                     temp.movementType   = "REDEEM";
   //                     rows.push(row); items.push(temp);
   //                 }
   //              } else if (this.defoptType === "WECHAT_PAYNOW"){
   //                 row['trsListingId'] = temp.trsListingId;
   //                 row['message'] = temp.message;
   //                 rows.push(row); items.push(temp);
   //              } else {
   //                if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'DASH'){
   //                      temp.movementType   ="DBS_PAYNOW_DASH";
   //                      row['movementType'] ="DBS_PAYNOW_DASH";
   //                }
   //                else if(temp.dbsTransactionType=='PAYNOW' && temp.dbsProxyType === 'VPA' && temp.dbsVpaType === 'GRAB'){
   //                      temp.movementType   ="DBS_PAYNOW_GRAB";
   //                      row['movementType'] ="DBS_PAYNOW_GRAB";  /* Defined variable for download excel */
   //                }
   //                else if(idrPaynow.includes(temp.paymentMethod)){
   //                     temp.movementType   ="IDR_PAYNOW";
   //                     row['movementType'] ="IDR_PAYNOW";  /* Defined variable for download excel */
   //                }
   //                else if(phpPaynow.includes(temp.paymentMethod)){
   //                     temp.movementType = "PHP_PAYNOW";
   //                     row['movementType'] = "PHP_PAYNOW";  /* Defined variable for download excel */
   //                }
   //                else if(!idrPaynow.includes(temp.paymentMethod) && !phpPaynow.includes(temp.paymentMethod) ){
   //                     row['movementType'] = temp.movementType; /* Defined variable for download excel */
   //                     row['paymentMethod'] = temp.paymentMethod; /* Additional fields for Remittance */
   //                     row['customerReference'] = temp.customerReference;
   //                }
   //                else if(temp.dbsTransactionType=='BUYNOW'){
   //                     row['movementType'] = "SGQR_BUYNOW";
   //                     temp.movementType   = "SGQR_BUYNOW";
   //                }
   //                else if(temp.dbsTransactionType=='PAYNOW' && proxyTypeList.includes(temp.dbsProxyType)){
   //                     row['movementType'] = "DBS_PAYNOW";
   //                     temp.movementType   = "DBS_PAYNOW";
   //                }
   //                else if(temp.systemTransactionType=='REDEEM'){
   //                     row['movementType'] = "REDEEM";
   //                     temp.movementType   = "REDEEM";
   //                }
   //                else if(temp.topUpCode){
   //                     var value = temp.topUpCode;
   //                     row['topUpCode'] = (value.substr(0,2)==='EW') ? value : '';
   //                }

   //                rows.push(row); items.push(temp);
   //              }
   //          }

   //          this.items = items;
   //          this.data  = rows;
   //          this.spinner = false;

   //      }).catch((e) => { console.log(e); });
   //  },

    amtFromChange: function(){
          if(this.amountTo){
            this.attrsFrom.description= "";
            this.attrsTo.description= "";
            //this.retrieveTransac();
          } else { this.attrsTo.description = "Amount To Required !" }
    },

    amtToChange: function () {
         if(this.amountFrom){
            this.attrsFrom.description= "";
            this.attrsTo.description= "";
            //this.retrieveTransac();
         } else { this.attrsFrom.description = "Amount From Required !"  }
    },

    timeChange(){
         var term = this.time; //console.log(this.time);
         var re = new RegExp("^[0-9:]*$");
         if (re.test(term)) {
           //this.retrieveTransac();
         }
    },

    filterChange(){
      //this.retrieveTransac();
    },

    timeRangeChange(){
       //this.retrieveTransac();
    },

    searchChange(){
       //this.retrieveTransac();
    },

    removedColField(keys){
       var cols = this.fields;  var rmkey = [];
       for (var key in cols) {
          var col = cols[key]; //console.log(col.key);
          if(keys.includes(col.key)){
              rmkey.push(key);
          }
       }

       var i = 1;
       for (var key2 in rmkey) {
          var val = rmkey[key2];
          var idx = (i===2) ? (val-1) : val;  //console.log(idx);
          cols.splice(idx,1);
          i++;
       }

       this.fields = cols;
    },

    typeChange(){
         var fields = []; var keys =[]; fields = this.fields;
         let col1  = {key:'paymentMethod',label:'Payment Method',sortable: true};
         let col2  = {key:'customerReference',label:'Customer Ref.',sortable: true};
         let col3  = {key:'topUpCode',label:'Top Up Code',sortable: true};
         let col4  = {key:'trsListingId',label:'Trs Listing ID',sortable: true};
         let col5  = {key:'message',label:'Reason',sortable: true};

         if(this.defoptType=="P2P"){
            this.seenCbOtUsr= true;
         } else { this.seenCbOtUsr= false; }

         if(this.defoptType=="REMITTANCE" || this.defoptType=="WALLET_GRAB_PHP"){
             var finalObj = fields.concat(col1,col2);
             this.fields  = finalObj;  keys.push("topUpCode");
         } else if(this.defoptType=="TOP_UP"){
            var finalObj = fields.concat(col3); this.fields  = finalObj;
            keys.push("paymentMethod","customerReference");
         } else if(this.defoptType=="WECHAT_PAYNOW"){
            var finalObj = fields.concat(col4,col5);
            this.fields  = finalObj;
         } else {
            keys.push("paymentMethod","customerReference","topUpCode","trsListingId","message");
         }

         this.removedColField(keys);
         this.setColumns();
         //this.retrieveTransac();
    },

    startDateOnChange(){
       //this.retrieveTransac();
    },

    endDateOnChange(){
       this.retrieveTransac();
    },

    toggleStsSelected(value) {
      if(!this.status.includes(value.name)){
         this.status.push(value.name);
         if(!this.initialLoad){
            //this.retrieveTransac();
         }
      }
    },

    toggleStsUnSelect(value, id){
         for( var i = 0; i < this.status.length; i++){
              if (this.status[i] === value.name) {
                  this.status.splice(i, 1);
              }
          }
          this.retrieveTransac();
    },

    toggleCurrSelected(value) {
      if(!this.currency.includes(value.name)){
         this.currency.push(value.name);
         //this.retrieveTransac();
         this.initialLoad = false;
      }
    },

    toggleCurrUnSelect(value, id){
         for( var i = 0; i < this.currency.length; i++){
              if ( this.currency[i] === value.name) {
                 delete this.currency[i];
              }
          }
         //this.retrieveTransac();
    },

    mounted() {
       //this.retrieveTransac();
    },

    },

  };

</script>

<style>
    .table{overflow-y:scroll; }
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue2-timepicker/dist/VueTimepicker.css"></style>
